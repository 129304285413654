import type { Cell } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../../choices/index';
import { modelDefinition } from '../../../model-definition/index';
import type { CasePrizesByCell, GoldCaseFeature, PendingGoldCasePrize } from '../../../shared/index';

export function evaluateGoldCases(
    candidates: Cell[],
    choices: Choices,
    coinAmount: number,
    allPrizes: CasePrizesByCell,
): { goldCaseCells: Cell[]; goldCasePrizes: PendingGoldCasePrize[]; allPrizes: CasePrizesByCell } {
    const playAmount = coinAmount * modelDefinition.coinAmountMultiplier;
    const goldCaseCells: Cell[] = [];
    const goldCasePrizes: PendingGoldCasePrize[] = [];

    // create a deep clone of `allPrizes` to avoid mutating the original
    const originalPrizes = allPrizes;
    allPrizes = allPrizes.map(({ cell, gold, prize, value }) => ({ cell, gold, prize, value }));

    for (const cell of candidates) {
        // First check if the candidate CASE cell _might_ become a GOLD_CASE. If not, skip to the next candidate.
        if (!choices.chooseIfCaseBecomesGoldCase(cell)) continue;

        // Calculate the sum of all prizes, and the candidate cell's prize, for use below.
        const casePrize = allPrizes.find(({ cell: prizeCell }) => isSameCell(cell, prizeCell));
        if (typeof casePrize?.prize !== 'number') {
            throw new Error('GOLD_CASE cell must have an ordinary prize');
        }
        if (casePrize.value === undefined) {
            throw new Error('GOLD_CASE cell must have a prize value');
        }
        const sumOfCasePrizes = allPrizes.reduce((total, { value }) => total + (value ?? 0), 0);

        // Choose which feature to evaluate.
        const goldCaseIndex = goldCasePrizes.length;
        const featureKind = choices.chooseGoldCaseFeature(goldCaseIndex);

        // Evaluate the chosen feature.
        let isGoldCaseKept: boolean;
        let feature: GoldCaseFeature;
        switch (featureKind) {
            case 'COLLECT': {
                const totalPrizesExclSelf = sumOfCasePrizes - casePrize.value;
                isGoldCaseKept = choices.chooseIfGoldCaseFeatureIsKept(featureKind, totalPrizesExclSelf / playAmount);
                if (isGoldCaseKept) {
                    // reset our own prize value, then apply the feature's effect
                    casePrize.value = casePrize.prize * coinAmount;
                    casePrize.value += totalPrizesExclSelf;
                }
                feature = 'COLLECT';
                break;
            }
            case 'MULTIPLY': {
                const totalPrizesExclSelf = sumOfCasePrizes - casePrize.value;
                const multiplier = choices.chooseGoldCaseMultiplier(goldCaseIndex, totalPrizesExclSelf / playAmount);
                isGoldCaseKept = choices.chooseIfGoldCaseFeatureIsKept(
                    featureKind,
                    (totalPrizesExclSelf * (multiplier - 1)) / playAmount,
                );
                if (isGoldCaseKept) {
                    // reset our own prize value, then apply the feature's effect
                    casePrize.value = casePrize.prize * coinAmount;
                    allPrizes.forEach((p) => {
                        if (isSameCell(p.cell, cell)) return;
                        p.value = (p.value ?? 0) * multiplier;
                    });
                }
                feature = `MULTIPLYx${multiplier}`;
                break;
            }
            case 'BOOST': {
                const totalCaseAmount = (allPrizes.length - 1) * casePrize.value;
                isGoldCaseKept = choices.chooseIfGoldCaseFeatureIsKept(featureKind, totalCaseAmount / playAmount);
                if (isGoldCaseKept) {
                    // reset our own prize value, then apply the feature's effect
                    casePrize.value = casePrize.prize * coinAmount;
                    allPrizes.forEach((p) => {
                        if (isSameCell(p.cell, cell)) return;
                        p.value = (p.value ?? 0) + (casePrize.value ?? 0);
                    });
                }
                feature = 'BOOST';
                break;
            }
        }

        if (!isGoldCaseKept) continue;
        goldCaseCells.push(cell);
        goldCasePrizes.push({ feature, prize: casePrize.prize });
    }

    // Make a copy of the original allPrizes, replacing the GOLD_CASE entries to indicate that they must be picked.
    const allPrizesUpdatedWithGoldCases: CasePrizesByCell = originalPrizes.map((prize) => {
        const goldCase = goldCaseCells.find((goldCaseCell) => isSameCell(goldCaseCell, prize.cell));
        return goldCase ? { cell: prize.cell, gold: 'PICK' } : prize;
    });

    return { goldCaseCells, goldCasePrizes, allPrizes: allPrizesUpdatedWithGoldCases };
}

function isSameCell(a: Cell, b: Cell) {
    return a[0] === b[0] && a[1] === b[1];
}
