import type { PlayArguments } from '@vgw/gdk-math-model-server';
import { createValueValidator } from '@vgw/gdk-math-model-tools';
import { modelDefinition } from '../../model-definition/index';
import type { Cell, GameState } from '../../shared/index';
import type { GameRequest } from './game-request';

export type SpinInputs = PlayArguments<GameRequest, GameState>;

export function assertSpinInputsValid({ coinType, gameRequest, gameState }: SpinInputs): void {
    // Ensure a valid coin amount has been sent
    if (!isValidCoinAmount(coinType, gameRequest.coinAmount)) {
        throw new Error('Invalid coin amount selected');
    }

    // Ensure banker offer is valid
    if (gameState?.bankerOfferAmount !== undefined && gameRequest.hasAcceptedBankerOffer === undefined) {
        throw new Error('Banker offer is required');
    }

    // Ensure play amount does not change during a round
    if (gameState && gameState.coinAmount !== gameRequest.coinAmount) {
        throw new Error('Coin amount changed during a round');
    }

    // Ensure the gold case pick is valid
    const goldCasePrizeCount = gameState?.caseChase?.goldCasePrizes?.length ?? 0;
    const goldCasePickCount = gameState?.caseChase?.goldCasePicks?.length ?? 0;
    if (goldCasePickCount < goldCasePrizeCount) {
        const goldCasePick = gameRequest.goldCasePick;
        if (!goldCasePick) throw new Error('Gold case pick is required');
        const prize = gameState?.caseChase?.allPrizes?.find(({ cell }) => isSameCell(cell, goldCasePick));
        if (!prize) throw new Error('Invalid gold case pick');
    }
}

const isValidCoinAmount = createValueValidator(modelDefinition.validCoinAmounts);

function isSameCell(a: Cell, b: Cell) {
    return a[0] === b[0] && a[1] === b[1];
}
