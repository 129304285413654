import type { Choices } from '../../../choices/choices';
import { modelDefinition } from '../../../model-definition/index';
import type { CasePrizesByCell, Cell } from '../../../shared/index';

export function selectCasePrizes(
    caseCells: Cell[],
    choices: Choices,
    coinAmount: number,
    oldPrizes: CasePrizesByCell,
): CasePrizesByCell {
    const casePrizes: CasePrizesByCell = [];
    const unavailablePrizes = oldPrizes.map((cp) => (typeof cp.prize === 'number' ? cp.prize : 0));

    for (const cell of caseCells) {
        const ordinaryCasePrizesRemaining = modelDefinition.casePrizeWeightsTable.length - unavailablePrizes.length;
        if (ordinaryCasePrizesRemaining > 0) {
            const prize = choices.chooseCasePrize({ cell, unavailablePrizes });
            const value = coinAmount * prize;
            casePrizes.push({ cell, prize, value });
            unavailablePrizes.push(prize);
        } else {
            casePrizes.push({ cell, prize: 'GRAND' });
            break;
        }
    }
    return casePrizes;
}
