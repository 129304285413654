import { type Static, Type } from '@sinclair/typebox';
import { CellSchema } from './cell';

export const CaseChasePhaseSchema = Type.Union([
    Type.Literal('START'),
    Type.Literal('IN_PROGRESS'),
    Type.Literal('END'),
]);

export type CaseChasePhase = Static<typeof CaseChasePhaseSchema>;

export const GoldCaseFeatureSchema = Type.Union([
    Type.Literal('COLLECT'),
    Type.Literal('MULTIPLYx2'),
    Type.Literal('MULTIPLYx3'),
    Type.Literal('MULTIPLYx4'),
    Type.Literal('MULTIPLYx5'),
    Type.Literal('BOOST'),
]);

export type GoldCaseFeature = Static<typeof GoldCaseFeatureSchema>;

export const CasePrizeSchema = Type.Object({
    cell: CellSchema,
    gold: Type.Optional(Type.Union([Type.Literal('PICK'), GoldCaseFeatureSchema])),
    prize: Type.Optional(Type.Union([Type.Number(), Type.Literal('GRAND')])),
    value: Type.Optional(Type.Number()),
});

export type CasePrize = Static<typeof CasePrizeSchema>;

export const CasePrizesByCellSchema = Type.Array(CasePrizeSchema);

export type CasePrizesByCell = Static<typeof CasePrizesByCellSchema>;

export const CaseChaseSchema = Type.Object({
    phase: CaseChasePhaseSchema,
    respins: Type.Number(),
    newPrizes: CasePrizesByCellSchema,
    allPrizes: CasePrizesByCellSchema,
});

export type CaseChase = Static<typeof CaseChaseSchema>;

export const PendingGoldCasePrizeSchema = Type.Object({
    feature: GoldCaseFeatureSchema, // eg BOOST, MULTIPLYx2, etc
    prize: Type.Number(), // eg 100, 200, 300, etc
});

export type PendingGoldCasePrize = Static<typeof PendingGoldCasePrizeSchema>;

export const GoldCasePickResultSchema = Type.Optional(
    Type.Object({
        goldCase: CasePrizeSchema,
        prizesBefore: Type.Array(CasePrizeSchema),
        prizesAfter: Type.Array(CasePrizeSchema),
    }),
);
