export function getWinType(info: {
    winAmount: number;
    isFreeSpinTrigger?: boolean;
    isFreeSpin?: boolean;
    isCaseChaseTriggered?: boolean;
    isCaseChaseRespin?: boolean;
    isJackpotTrigger?: boolean;
}) {
    if (info.isJackpotTrigger) return 'JACKPOT_WIN';
    if (info.isCaseChaseTriggered) return 'BONUS_WIN';
    if (info.isFreeSpinTrigger) return 'SCATTER_WIN';
    if (info.isFreeSpin ?? info.isCaseChaseRespin) return info.winAmount > 0 ? 'FREE_SPIN_WIN' : 'FREE_SPIN_NO_WIN';
    return info.winAmount > 0 ? 'NORMAL_WIN' : 'NO_WIN';
}
