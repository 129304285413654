import type { Choices } from '../../../choices/index';
import type { Cell, GameState } from '../../../shared/index';
import { type GameResponse } from '../game-response';
import type { SpinInputs } from '../inputs';
import type { SpinOutcome } from '../outcome';

export function pickGoldCase(spinInputs: SpinInputs, _: Choices): SpinOutcome {
    const { gameRequest, gameState } = spinInputs;
    const { coinAmount, goldCasePick } = gameRequest;
    if (!gameState?.caseChase) {
        throw new Error('GameState.caseChase must be defined during case chase');
    }
    if (!goldCasePick) {
        throw new Error('GameRequest.goldCasePick must be defined during gold case pick');
    }
    if (!gameState.caseChase.goldCasePrizes) {
        throw new Error('pendingGoldCasePrizes must be defined during gold case pick');
    }
    if (gameState.caseChase.goldCasePicks === undefined) {
        throw new Error('goldCasePicks must be defined during gold case pick');
    }

    const prizesBefore = gameState.caseChase.allPrizes;
    const prizesAfter = [...prizesBefore.map((casePrize) => ({ ...casePrize }))];
    const pickedGoldCase = gameState.caseChase.goldCasePrizes[gameState.caseChase.goldCasePicks.length];
    const self = prizesAfter.find(({ cell }) => isSameCell(cell, goldCasePick));
    if (self === undefined) {
        throw new Error('picked gold case cell must be in the prize list');
    }
    const getPrizeValue = (prize?: number | 'GRAND') => (typeof prize === 'number' ? prize : 0) * coinAmount;

    self.gold = pickedGoldCase.feature;
    self.prize = pickedGoldCase.prize;
    self.value = getPrizeValue(pickedGoldCase.prize);

    switch (pickedGoldCase.feature) {
        case 'COLLECT': {
            self.value = prizesAfter.reduce((sum, { value }) => sum + (value ?? 0), 0);
            break;
        }
        case 'MULTIPLYx2':
        case 'MULTIPLYx3':
        case 'MULTIPLYx4':
        case 'MULTIPLYx5': {
            const multiplier = Number(pickedGoldCase.feature.slice(-1));
            for (const p of prizesAfter) {
                if (p === self || p.gold === 'PICK') continue;
                p.value = (p.value ?? 0) * multiplier;
            }
            break;
        }
        case 'BOOST': {
            const boostValue = self.value;
            for (const p of prizesAfter) {
                if (p === self || p.gold === 'PICK') continue;
                p.value = (p.value ?? 0) + boostValue;
            }
            break;
        }
    }

    const gameResponse: GameResponse = {
        reelStripPositions: gameState.reelStripPositions,
        slotWindow: gameState.slotWindow,
        coinAmount,
        playAmount: 0,
        winAmount: 0,
        lineWins: [],
        winType: 'FREE_SPIN_NO_WIN',
        cumulativeWinAmount: gameState.cumulativeWinAmount,
        freeSpinPhase: gameState.freeSpinPhase,
        freeSpinCount: gameState.freeSpinCount,
        goldCaseCells: [],
        caseChase: {
            phase: 'IN_PROGRESS',
            respins: gameState.caseChase.respins,
            newPrizes: [self],
            allPrizes: prizesAfter,
        },
        goldCasePickResult: { goldCase: self, prizesBefore, prizesAfter },
    };
    const newGameState: GameState = {
        reelStripPositions: gameState.reelStripPositions,
        slotWindow: gameState.slotWindow,
        goldCaseCells: [],
        coinAmount,
        cumulativeWinAmount: gameState.cumulativeWinAmount,
        freeSpinPhase: gameState.freeSpinPhase,
        freeSpinCount: gameState.freeSpinCount,
        caseChase: {
            phase: 'IN_PROGRESS',
            respins: gameState.caseChase.respins,
            newPrizes: [self],
            allPrizes: prizesAfter,
            hardPosition: gameState.caseChase.hardPosition,
            mediumPosition: gameState.caseChase.mediumPosition,
            goldCasePrizes: gameState.caseChase.goldCasePrizes,
            goldCasePicks: [...gameState.caseChase.goldCasePicks, goldCasePick],
            goldCasePickResult: { goldCase: self, prizesBefore, prizesAfter },
        },
    };

    return {
        gameResponse,
        gameState: newGameState,
        playSummary: { playAmount: 0, winAmount: 0, roundComplete: false },
    };
}

function isSameCell(a: Cell, b: Cell) {
    return a[0] === b[0] && a[1] === b[1];
}
