import { createSlotWindowGenerator, filterSlotWindow } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../choices/index';
import { modelDefinition } from '../../model-definition/index';
import type { CaseChase, Cell, GameState, PendingGoldCasePrize } from '../../shared/index';
import { evaluateBankerCaseFeature, evaluateBankerScatFeature } from './banker-features/index';
import { evaluateBankerMultiplierFeature, evaluateBankerStacksFeature } from './banker-features/index';
import { evaluateGoldCases, selectCasePrizes } from './case-chase/index';
import { createWinsEvaluator } from './create-wins-evaluator';
import { getWinType } from './get-win-type';
import type { SpinInputs } from './inputs';
import type { SpinOutcome } from './outcome';

export function spinFree(spinInputs: SpinInputs, choices: Choices): SpinOutcome {
    if (!spinInputs.gameState) {
        throw new Error('GameState.caseChase must be defined during freeSpins');
    }
    const { coinType, gameRequest, gameState: lastGameState } = spinInputs;
    const coinAmount = gameRequest.coinAmount;
    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;
    const oldCumulativeWinAmount = lastGameState.cumulativeWinAmount;

    // Generate Slot Window
    const reelStripPositions = choices.chooseReelStripPositions();
    let slotWindow = generateSlotWindow(reelStripPositions);
    const isFreeSpin = true;

    // Evaluate Banker Features and line wins
    const { bankerStacks, updatedSlotWindow } = evaluateBankerStacksFeature(slotWindow, choices, isFreeSpin) ?? {};
    slotWindow = updatedSlotWindow ?? slotWindow;
    const winInfo = evaluateWins({ slotWindow, coinAmount, playAmount, coinType });
    const bankerMultiplier = evaluateBankerMultiplierFeature(slotWindow, winInfo, choices, isFreeSpin);
    const bankerScat = evaluateBankerScatFeature(slotWindow, winInfo, choices, isFreeSpin);
    const bankerCase = evaluateBankerCaseFeature(slotWindow, winInfo, choices, isFreeSpin, bankerStacks);
    const winAmount = winInfo.winAmount * (bankerMultiplier ?? 1);

    // Evaluate free spin retrigger
    const freeSpinsAwarded = modelDefinition.freeSpinRetriggerCount;
    const isFreeSpinReTrigger = winInfo.scatterWin !== undefined;

    // Compute win type and amount
    const cumulativeWinAmount = oldCumulativeWinAmount + winAmount;

    const winType = getWinType({
        winAmount,
        isFreeSpinTrigger: isFreeSpinReTrigger,
        isFreeSpin: true,
    });

    // Calculate if Case Chase is triggered
    const caseCells = filterSlotWindow(slotWindow, (symbol) => symbol === 'CASE');
    const caseCount = caseCells.length;
    const isCaseChaseTriggered = caseCount >= 6;
    let caseChase: CaseChase | undefined;
    let goldCaseCells: Cell[];
    let goldCasePrizes: PendingGoldCasePrize[] = [];
    if (isCaseChaseTriggered) {
        let allPrizes = selectCasePrizes(caseCells, choices, coinAmount, []);
        ({ goldCaseCells, goldCasePrizes, allPrizes } = evaluateGoldCases(caseCells, choices, coinAmount, allPrizes));
        caseChase = { phase: 'START', respins: 3, newPrizes: allPrizes, allPrizes };
    } else {
        // Even if case chase is _not_ triggered, we may still turn some CASEs into GOLD_CASEs (won't affect win eval)
        goldCaseCells = caseCells.filter((cell) => choices.chooseIfCaseBecomesGoldCase(cell));
    }

    // Calculate new game state
    let { freeSpinCount, freeSpinPhase } = lastGameState;
    if (freeSpinCount === undefined || freeSpinPhase === undefined) {
        throw new Error('Game state does not exist when attempting a free spin');
    }
    freeSpinCount -= 1;
    freeSpinPhase = freeSpinCount > 0 ? 'IN_PROGRESS' : 'END';

    if (isFreeSpinReTrigger) {
        freeSpinPhase = 'RETRIGGER';

        freeSpinCount += freeSpinsAwarded;
    }

    const roundComplete = freeSpinPhase === 'END' && !isCaseChaseTriggered;
    const gameState: GameState | undefined = roundComplete
        ? undefined
        : {
              coinAmount,
              reelStripPositions,
              slotWindow,
              goldCaseCells,
              cumulativeWinAmount,
              freeSpinCount,
              freeSpinPhase,
              caseChase: caseChase && {
                  ...caseChase,
                  ...choices.chooseHardAndMediumPositions(slotWindow),
                  goldCasePrizes,
                  goldCasePicks: [],
              },
          };

    return {
        playSummary: {
            playAmount: 0,
            winAmount,
            roundComplete,
        },
        gameResponse: {
            winAmount,
            cumulativeWinAmount,

            reelStripPositions,
            slotWindow,

            lineWins: winInfo.lineWins,
            winCells: winInfo.winCells,
            scatterWin: winInfo.scatterWin,
            coinAmount: gameRequest.coinAmount,
            freeSpinCount,
            freeSpinPhase,
            playAmount,
            winType,

            bankerStacks,
            bankerMultiplier,
            bankerScat,
            bankerCase,

            goldCaseCells,
            caseChase,
        },
        gameState,
    };
}

const generateSlotWindow = createSlotWindowGenerator(modelDefinition);
const evaluateWins = createWinsEvaluator(modelDefinition);
