import { filterSlotWindow } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../../choices/index';
import type { CaseChasePhase, CasePrizesByCell, Cell, GameState, PendingGoldCasePrize } from '../../../shared/index';
import { type GameResponse } from '../game-response';
import { getWinType } from '../get-win-type';
import type { SpinInputs } from '../inputs';
import type { SpinOutcome } from '../outcome';
import { evaluateGoldCases } from './evaluate-gold-cases';
import { selectCasePrizes } from './select-case-prizes';

export function respin(spinInputs: SpinInputs, choices: Choices): SpinOutcome {
    if (!spinInputs.gameState) {
        throw new Error('GameState.caseChase must be defined during case chase');
    }
    const { coinType, gameRequest, gameState } = spinInputs;
    const { coinAmount } = gameRequest;
    const { reelStripPositions = [], freeSpinPhase, freeSpinCount, caseChase } = gameState;
    if (!caseChase) {
        throw new Error('caseChase must be defined during case chase');
    }
    const { respins: oldRespins, allPrizes: oldPrizes, hardPosition, mediumPosition } = caseChase;
    const oldSlotWindow = gameState.slotWindow;
    const oldCumulativeWinAmount = gameState.cumulativeWinAmount ?? 0;

    // Determine the non-CASE cells which will become CASEs, and update the slot window with the new CASEs
    const nonCaseCells = filterSlotWindow(oldSlotWindow, (sym) => sym !== 'CASE');
    const newCaseCells = nonCaseCells.filter((cell) =>
        choices.chooseIfCellBecomesCaseOnRespin({ coinType, coinAmount, cell, hardPosition, mediumPosition }),
    );
    const slotWindow = oldSlotWindow.map((col) => [...col]); // clone the slot window so we don't mutate the original one
    newCaseCells.forEach(([row, col]) => (slotWindow[col][row] = 'CASE'));

    // Choose a prize for each new CASE, and combine new CASEs with old ones.
    let newPrizes = selectCasePrizes(newCaseCells, choices, coinAmount, oldPrizes);
    let allPrizes = combineCasePrizes(oldPrizes, newPrizes);

    // Count total CASE symbols in the window after updates
    const totalCaseSymbols = slotWindow.flat().filter((symbol) => symbol === 'CASE').length;
    const isGrandTrigger = totalCaseSymbols === 15;

    // Evaluate gold cases. Skip this if the GRAND jackpot is triggered.
    let goldCaseCells: Cell[] = [];
    let goldCasePrizes: PendingGoldCasePrize[] = [];
    if (!isGrandTrigger) {
        const goldCases = evaluateGoldCases(newCaseCells, choices, coinAmount, allPrizes);
        ({ goldCaseCells, goldCasePrizes, allPrizes } = goldCases);
        newPrizes = allPrizes.filter(({ cell }) => newCaseCells.some((newCell) => isSameCell(cell, newCell)));
    }

    // Update case chase feature state
    const didAnyNewCasesAppear = newPrizes.length > 0;
    const jackpotWinCounts = isGrandTrigger ? { GRAND: 1 } : undefined;
    const respins = isGrandTrigger ? 0 : didAnyNewCasesAppear ? 3 : oldRespins - 1;
    const phase: CaseChasePhase = respins > 0 ? 'IN_PROGRESS' : 'END';

    const winAmount = phase === 'END' ? getTotalValueOfFixedCasePrizes(allPrizes) : 0;
    const cumulativeWinAmount = oldCumulativeWinAmount + winAmount;
    const winType = getWinType({ winAmount, isCaseChaseRespin: true, isJackpotTrigger: isGrandTrigger });
    const roundComplete = !respins && !gameState.freeSpinCount;
    const gameResponse: GameResponse = {
        reelStripPositions,
        slotWindow,
        coinAmount,
        playAmount: 0,
        winAmount,
        lineWins: [],
        winType,
        cumulativeWinAmount,
        freeSpinPhase,
        freeSpinCount,
        goldCaseCells,
        jackpotWinCounts,
        caseChase: {
            phase,
            respins,
            newPrizes,
            allPrizes,
        },
    };
    const newGameState: GameState = {
        coinAmount,
        reelStripPositions,
        slotWindow,
        goldCaseCells,
        cumulativeWinAmount,
        freeSpinPhase,
        freeSpinCount,
        caseChase: {
            phase,
            respins,
            newPrizes,
            allPrizes,
            hardPosition,
            mediumPosition,
            goldCasePrizes,
            goldCasePicks: [],
        },
    };

    // Only return GameState if the next spin will be a feature spin
    return {
        gameResponse,
        gameState: roundComplete ? undefined : newGameState,
        playSummary: { playAmount: 0, winAmount, roundComplete, jackpotWinCounts },
    };
}

function getTotalValueOfFixedCasePrizes(casePrizes: CasePrizesByCell) {
    return casePrizes.reduce((sum, prize) => sum + (prize.value ?? 0), 0);
}

/** Combine two disjoint lists of CASE prizes into a single sorted list. */
function combineCasePrizes(prizes1: CasePrizesByCell, prizes2: CasePrizesByCell) {
    const casePrizes = prizes1.concat(prizes2); // NB: this creates a new array and leaves the inputs unchanged.
    casePrizes.sort(({ cell: [arow, acol] }, { cell: [brow, bcol] }) => {
        if (acol < bcol) return -1;
        if (acol > bcol) return 1;
        if (arow < brow) return -1;
        if (arow > brow) return 1;
        throw new Error('combineCasePrizes: lists are not disjoint');
    });
    return casePrizes;
}

function isSameCell(a: Cell, b: Cell) {
    return a[0] === b[0] && a[1] === b[1];
}
