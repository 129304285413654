import { type Static, Type } from '@sinclair/typebox';
import { CaseChaseSchema, CellSchema, FreeSpinPhaseSchema, GoldCasePickResultSchema } from '../../shared/index';

export const GameResponseSchema = Type.Object({
    coinAmount: Type.Number(),
    playAmount: Type.Integer(),
    winAmount: Type.Integer(),
    winCells: Type.Optional(Type.Array(Type.Array(CellSchema))),
    cumulativeWinAmount: Type.Optional(Type.Number()),
    discardedWinAmount: Type.Optional(Type.Number()),

    reelStripPositions: Type.Array(Type.Integer()),
    slotWindow: Type.Array(Type.Array(Type.String())),

    lineWins: Type.Array(
        Type.Object({
            playLineIndex: Type.Integer(),
            symbol: Type.String(),
            length: Type.Number(),
            multiplier: Type.Number(),
            winAmount: Type.Number(),
        }),
    ),
    scatterWin: Type.Optional(
        Type.Object({
            cells: Type.Array(CellSchema),
            multiplier: Type.Number(),
            symbol: Type.String(),
            winAmount: Type.Number(),
        }),
    ),

    winType: Type.Union([
        Type.Literal('NO_WIN'),
        Type.Literal('NORMAL_WIN'),
        Type.Literal('FREE_SPIN_NO_WIN'),
        Type.Literal('FREE_SPIN_WIN'),
        Type.Literal('SCATTER_WIN'),
        Type.Literal('BONUS_WIN'),
        Type.Literal('JACKPOT_WIN'),
    ]),

    freeSpinPhase: Type.Optional(FreeSpinPhaseSchema),
    freeSpinCount: Type.Optional(Type.Integer()),

    bankerOfferAmount: Type.Optional(Type.Integer()),
    hasAcceptedBankerOffer: Type.Optional(Type.Boolean()),

    bankerStacks: Type.Optional(
        Type.Object({
            symbol: Type.Union([Type.Literal('WILD'), Type.Literal('CASE')]),
            reels: Type.Array(Type.Integer()),
            originalSlotWindow: Type.Array(Type.Array(Type.String())),
        }),
    ),

    bankerMultiplier: Type.Optional(Type.Integer()),

    bankerScat: Type.Optional(Type.Object({ cell: CellSchema, symbol: Type.String() })),

    bankerCase: Type.Optional(Type.Object({ cell: CellSchema, symbol: Type.String() })),

    goldCaseCells: Type.Array(CellSchema),

    caseChase: Type.Optional(CaseChaseSchema),
    goldCasePickResult: Type.Optional(GoldCasePickResultSchema),

    jackpotWinCounts: Type.Optional(
        Type.Object({
            GRAND: Type.Integer(),
        }),
    ),
});

export type GameResponse = Static<typeof GameResponseSchema>;
