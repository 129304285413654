import { createSlotWindowGenerator, filterSlotWindow } from '@vgw/gdk-math-model-tools';
import type { Choices } from '../../choices/index';
import { modelDefinition } from '../../model-definition/index';
import type { CaseChase, Cell, FreeSpinPhase, GameState, PendingGoldCasePrize } from '../../shared/index';
import { evaluateBankerCaseFeature, evaluateBankerScatFeature } from './banker-features/index';
import { evaluateBankerMultiplierFeature, evaluateBankerStacksFeature } from './banker-features/index';
import { evaluateGoldCases, selectCasePrizes } from './case-chase/index';
import { createWinsEvaluator } from './create-wins-evaluator';
import { getWinType } from './get-win-type';
import type { SpinInputs } from './inputs';
import type { SpinOutcome } from './outcome';

export function spinBase({ coinType, gameRequest }: SpinInputs, choices: Choices): SpinOutcome {
    const coinAmount = gameRequest.coinAmount;
    const playAmount = gameRequest.coinAmount * modelDefinition.coinAmountMultiplier;

    // Generate Slot Window
    const reelStripPositions = choices.chooseReelStripPositions();
    let slotWindow = generateSlotWindow(reelStripPositions);
    const isFreeSpin = false;

    // Evaluate Banker Features and line wins
    const { bankerStacks, updatedSlotWindow } = evaluateBankerStacksFeature(slotWindow, choices, isFreeSpin) ?? {};
    slotWindow = updatedSlotWindow ?? slotWindow;
    const winInfo = evaluateWins({ slotWindow, coinAmount, playAmount, coinType });
    const bankerMultiplier = evaluateBankerMultiplierFeature(slotWindow, winInfo, choices, isFreeSpin);
    const bankerScat = evaluateBankerScatFeature(slotWindow, winInfo, choices, isFreeSpin);
    const bankerCase = evaluateBankerCaseFeature(slotWindow, winInfo, choices, isFreeSpin, bankerStacks);

    // Calculate if freeSpins is triggered
    const isFreeSpinsTriggered = winInfo.scatterWin !== undefined;
    let freeSpinCount: number | undefined;
    let freeSpinPhase: FreeSpinPhase | undefined;
    if (isFreeSpinsTriggered) {
        freeSpinCount = 5;
        freeSpinPhase = 'START';
    }

    // Calculate if Case Chase is triggered
    const caseCells = filterSlotWindow(slotWindow, (symbol) => symbol === 'CASE');
    const caseCount = caseCells.length;
    const isCaseChaseTriggered = caseCount >= 6;
    let caseChase: CaseChase | undefined;
    let goldCaseCells: Cell[];
    let goldCasePrizes: PendingGoldCasePrize[] = [];
    if (isCaseChaseTriggered) {
        let allPrizes = selectCasePrizes(caseCells, choices, coinAmount, []);
        ({ goldCaseCells, goldCasePrizes, allPrizes } = evaluateGoldCases(caseCells, choices, coinAmount, allPrizes));
        caseChase = { phase: 'START', respins: 3, newPrizes: allPrizes, allPrizes };
    } else {
        // Even if case chase is _not_ triggered, we may still turn some CASEs into GOLD_CASEs (won't affect win eval)
        goldCaseCells = caseCells.filter((cell) => choices.chooseIfCaseBecomesGoldCase(cell));
    }

    // Compute win type and amount
    const winAmount = winInfo.winAmount * (bankerMultiplier ?? 1);
    const winType = getWinType({
        winAmount,
        isFreeSpinTrigger: isFreeSpinsTriggered,
        isFreeSpin: false,
        isCaseChaseTriggered,
    });

    // Calculate if banker offer is awarded
    const scatCount = slotWindow.flat().filter((symbol) => symbol === 'SCAT').length;
    const isBankerOfferAwarded =
        bankerScat === undefined &&
        bankerMultiplier === undefined &&
        bankerCase === undefined &&
        bankerStacks === undefined &&
        scatCount < 3 &&
        caseCount < 6 &&
        winAmount > 0 &&
        choices.chooseIfBankerOfferIsAwarded(winAmount, playAmount);

    // Evaluate banker offer
    if (isBankerOfferAwarded) {
        const bankerOfferAmount = choices.chooseBankerOfferWinAmount(winAmount, playAmount);
        return {
            playSummary: {
                playAmount,
                winAmount: 0,
                roundComplete: false,
            },
            gameResponse: {
                playAmount,
                winAmount: 0,
                coinAmount: gameRequest.coinAmount,
                reelStripPositions: [],
                slotWindow: [],
                lineWins: [],
                winType: 'NO_WIN',
                bankerOfferAmount,
                goldCaseCells: [],
            },
            gameState: {
                coinAmount,
                reelStripPositions,
                slotWindow,
                goldCaseCells,
                cumulativeWinAmount: 0,
                bankerOfferAmount,
            },
        };
    }

    const roundComplete = !isFreeSpinsTriggered && !isCaseChaseTriggered;
    let gameState: GameState | undefined;

    if (!roundComplete) {
        gameState = {
            coinAmount,
            reelStripPositions,
            slotWindow,
            goldCaseCells,
            cumulativeWinAmount: winAmount,
            freeSpinCount,
            freeSpinPhase,
            caseChase: caseChase && {
                ...caseChase,
                ...choices.chooseHardAndMediumPositions(slotWindow),
                goldCasePrizes,
                goldCasePicks: [],
            },
        };
    }

    return {
        playSummary: {
            playAmount,
            winAmount,
            roundComplete,
        },
        gameResponse: {
            playAmount,
            winAmount,
            cumulativeWinAmount: gameState?.cumulativeWinAmount,

            reelStripPositions,
            slotWindow,
            winType,
            lineWins: winInfo.lineWins,
            winCells: winInfo.winCells,
            scatterWin: winInfo.scatterWin,
            coinAmount: gameRequest.coinAmount,

            freeSpinPhase,
            freeSpinCount,

            bankerStacks,
            bankerMultiplier,
            bankerScat,
            bankerCase,

            goldCaseCells,
            caseChase,
        },
        gameState,
    };
}

const evaluateWins = createWinsEvaluator({
    ...modelDefinition,
});
const generateSlotWindow = createSlotWindowGenerator(modelDefinition);
