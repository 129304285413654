import { ScenarioSchema, createChoicesFromScenario, createRandomChoices } from './choices/index';
import { modelDefinition } from './model-definition/index';
import { GameRequestSchema, GameResponseSchema, InitResponseSchema, init, play } from './operations/index';
import { GameStateSchema } from './shared/index';

export type { GameRequest, GameResponse, SpinInputs, SpinOutcome } from './operations/index';
export type { GameState } from './shared/index';

export const model = {
    play,
    init,
    definition: modelDefinition,
    choices: {
        createRandomChoices,
        createChoicesFromScenario,
    },
    schemas: {
        initResponseSchema: InitResponseSchema,
        gameRequestSchema: GameRequestSchema,
        gameResponseSchema: GameResponseSchema,
        gameStateSchema: GameStateSchema,
        scenarioSchema: ScenarioSchema,
    },
};
