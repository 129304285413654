import { modelDefinition } from '../../model-definition/index';

export function getWinCells(playLineIndex: number, winLength: number) {
    return precomputeWinCellsByLineAndLength[playLineIndex][winLength];
}

const precomputeWinCellsByLineAndLength = precomputeAllWinCells();
function precomputeAllWinCells() {
    const winCellsByLineAndLength: [row: number, col: number][][][] = [];
    const playLineIndices = modelDefinition.playLines.map((_, i) => i);
    const winLengths = [3, 4, 5]; // Only 3 x Symbol in the winTable

    for (const playLineIndex of playLineIndices) {
        winCellsByLineAndLength[playLineIndex] = [];
        const playLine = modelDefinition.playLines[playLineIndex];
        for (const winLength of winLengths) {
            const winCells = playLine.slice(0, winLength).map((row, col): [row: number, col: number] => [row, col]);
            winCellsByLineAndLength[playLineIndex][winLength] = winCells;
        }
    }
    return winCellsByLineAndLength;
}
